<template>
    <v-container fluid class="down-top-padding">
        <!--<v-parallax height="300"
                src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg">
    </v-parallax>-->
        <v-row>
            <v-col cols="12" sm="12" lg="6">
                <v-card>
                    <v-card-title>
                        <input ref="uploader" class="d-none" type="file" @change="onFileChanged"
                               :rules="rules"
                               accept="application/json">
                        <v-icon left>mdi-check</v-icon>
                        <span class="text-h6 font-weight-light">{JSON Data}</span>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="ma-1" title="Format Json" @click="moveFormat" :disabled="isDataAdded">
                            <v-icon dark>mdi-folder-move</v-icon>
                        </v-btn>
                        <v-btn color="primary" class="ma-1" title="Sample Data" @click="sampleJsonData">
                            <v-icon dark>mdi-text</v-icon>
                        </v-btn>
                        <v-btn color="primary" class="ma-1" title="Import File" :loading="isSelecting" @click="handleFileImport">
                            <v-icon dark>mdi-folder-download</v-icon>
                        </v-btn>
                        <v-btn color="primary" class="ma-1" title="Clear" @click="deleteJsonData" :disabled="isDataAdded">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn color="primary" class="ma-1" title="Save" @click="saveJsonData" :disabled="isDataAdded">
                            <v-icon dark>mdi-content-save</v-icon>
                        </v-btn>
                    </v-card-title>
                    <vue-json-editor v-model="json" :show-btns="true" :expandedOnStart="true" @json-change="onJsonChange"></vue-json-editor>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" lg="6">
                <v-card>
                    <v-card-title>
                        <v-icon left>mdi-check-all</v-icon>
                        <span class="text-h6 font-weight-light">{JSON Data-Copy}</span>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="ma-1" title="Clear" @click="deleteJsonCopyData" :disabled="isCopyDataAdded">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn color="primary" class="ma-1" title="Save" @click="saveJsonCopyData" :disabled="isCopyDataAdded">
                            <v-icon dark>mdi-content-save</v-icon>
                        </v-btn>
                    </v-card-title>
                    <vue-json-editor v-model="jsonCope" :show-btns="true" :expandedOnStart="true" @json-change="onJsonCopyChange"></vue-json-editor>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="12">
                <BaseCard heading="What are the JSON data?">
                    <v-list-item-subtitle class="text-wrap line-h20">
                        <span class="grey--text text--darken-4 font-weight-bold">
                            JSON (JavaScript Object Notation)
                        </span> is a lightweight data interchange format that is easy for humans to read and write and easy for machines to parse and generate.
                        It is a text-based format that is commonly used for representing structured data.
                        JSON is often used for data transmission between a server and a web application,
                        as it provides a standardized format that is widely supported across different programming languages.
                        It is also commonly used for configuration files and storing data in key-value pairs.
                        The basic structure of <span class="grey--text text--darken-4 font-weight-bold">
                            JSON consists of objects (collections of key-value pairs), arrays (ordered lists of values), and primitive data types such as strings, numbers, booleans, and null
                        </span>. Objects are enclosed in curly braces<code>{}</code> , arrays are enclosed in square brackets <code>[]</code>, and <code>key-value</code> pairs are separated by colons <code>:</code>.
                        Here's an example of a simple JSON object:
                        <code>
                            {
                              "name": "John Doe",
                              "age": 30,
                              "city": "New York"
                            }
                        </code>
                    </v-list-item-subtitle>
                        </BaseCard>

                <BaseCard heading="{JSON Data}">
                    <v-list-item-subtitle class="text-wrap line-h20">
                        In the left side panel, you can copy and paste your data.It will make all of the button icons active. 
                        Using the import button icon, import your file. If your JSON text has any errors, an error message will be displayed.
                    </v-list-item-subtitle>
                </BaseCard> 
                <BaseCard heading="{JSON Data-Copy}">
                    <v-list-item-subtitle class="text-wrap line-h20">
                        The right side panel, where your cope file may be edited and compared, is where you can relocate your data once it has been pasted.
                    </v-list-item-subtitle>
                </BaseCard>

                </v-col>
        </v-row>
        </v-container>
</template>

<script>
    import vueJsonEditor from 'vue-json-editor'
export default {
        name: "Jsoneditor",
        metaInfo: {
            title: 'Iconvu.com',
            titleTemplate: '%s | Tools-Json Editor'
        },
        components: {
            vueJsonEditor,
        },
    data: () => ({
        json: {},
        jsonCope: {},
        isSelecting: false,
        selectedFile: null,
        rules: [
            value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
        ],
        isDataAdded: true,
        isCopyDataAdded: true
    }),
    methods: {
        onJsonChange(value) {
            //console.log(JSON.stringify(value))

            let json = JSON.stringify(value);
            if (json.length > 2) {
                this.isDataAdded = false;
            }
            else {
                this.isDataAdded = true;
            }
        },
        onJsonCopyChange(value) {
           // console.log(JSON.stringify(value))
            let jsonCopy = JSON.stringify(value);
            if (jsonCopy.length > 2) {
                this.isCopyDataAdded = false;
            }
            else {
                this.isCopyDataAdded = true;
            }
        },
        sampleJsonData() {
            this.json =[
                {
                    "employeeId": "3941",
                    "employeeFirstName": "Michelle",
                    "employeeLastName": "Walker",
                    "displayName": "Michelle Walker",
                    "address": "278 Bayview Ave",
                    "city": "Milan",
                    "country": "Monroe",
                    "state": "MI",
                    "zip": "48160",
                    "phoneNumber": "734-561-6170",
                    "dateOfJoining": "01/11/2016 00:00:00",
                    "department": "IT",
                    "jobStatus": "A",
                    "emailAddress": "Michelle Walker@gmail.com",
                    "dateOfBirth": null,
                    "bloodGroup": "02/15/1989 00:00:00",
                    "isActive": "1  ",
                    "gender": "Female    ",
                    "companyName": "Ulbrich Trucking",
                    "portingStatus": null,
                    "jobCode": "No        ",
                    "maritalStatus": "No",
                    "birthCountry": "Milan",
                    "faceBookURL": "Michelle Walker@facebook.com",
                    "linkedInURL": "Michelle Walker@LinkedIn.com",
                    "isExperience": "True",
                    "lastUpdateDateTime": "07/26/2022 00:00:00"
                },
                {
                    "employeeId": "8041",
                    "employeeFirstName": "Joanna",
                    "employeeLastName": "Marquez",
                    "displayName": "Joanna Marquez",
                    "address": "6980 Dorsett Rd",
                    "city": "Abilene",
                    "country": "Dickinson",
                    "state": "KS",
                    "zip": "67410",
                    "phoneNumber": "785-463-7829",
                    "dateOfJoining": "04/17/2016 00:00:00",
                    "department": "Finance",
                    "jobStatus": "A",
                    "emailAddress": "Joanna Marquez@gmail.com",
                    "dateOfBirth": null,
                    "bloodGroup": "12/19/2011 00:00:00",
                    "isActive": "1  ",
                    "gender": "Male      ",
                    "companyName": "Eder Assocs Consltng Engrs Pc",
                    "portingStatus": null,
                    "jobCode": "Yes       ",
                    "maritalStatus": "No",
                    "birthCountry": "Abilene",
                    "faceBookURL": "Joanna Marquez@facebook.com",
                    "linkedInURL": "Joanna Marquez@LinkedIn.com",
                    "isExperience": "True",
                    "lastUpdateDateTime": "07/20/2022 00:00:00"
                }]
            this.isDataAdded = false;
        },
        addJsonData() {

        },
        deleteJsonData() {
            this.json = {};
            this.isDataAdded = true;
        },
        deleteJsonCopyData() {
            this.jsonCope = {};
            this.isCopyDataAdded = true;
        },
        moveFormat() {
            this.jsonCope = this.json;
            this.isCopyDataAdded = false;
        },
        saveJsonCopyData() {
            const data = JSON.stringify(this.jsonCope)
            const blob = new Blob([data], { type: 'text/plain' })
            const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
            a.download = "IconvuJsonData.json";
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        saveJsonData() {
            const data = JSON.stringify(this.json)
            const blob = new Blob([data], { type: 'text/plain' })
            const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
            a.download = "IconvuJsonData.json";
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
        onFileChanged(e) {
            this.selectedFile = e.target.files[0];
            //console.log(this.selectedFile);
            if (this.selectedFile.length <= 0) {
                return false;
            }
            const fr = new FileReader();
            fr.onload = e => {
                this.json = JSON.parse(e.target.result);
                this.isDataAdded = false;
            }
            fr.readAsText(this.selectedFile);
           
        },
        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true });
            this.$refs.uploader.click();
        }
    }
};
</script>
<style lang="scss">
    .jsoneditor-menu {
        background-color: #00836f !important;
        border-bottom: 1px solid #c29976 !important;
    }
    .jsoneditor {
        border: 1px solid #00836f !important;
        height: 550px !important;
    }
</style>